import { useTranslation } from 'next-i18next';

import { constructCategoryUrl } from '@/core/services/constructCategoryUrl';
import { CategoryProductsSectionCarousel } from '@/core/ui/components/CategoryProductSectionCarousel/CategoryProductSectionCarousel';
import { cn } from '@/core/ui/utils';

import { HomeCategoryProductsSection } from '../../queries/types';

type CategoryProductsSectionProps = {
  section: HomeCategoryProductsSection;
};
export const CategoryProductsSection = ({
  section,
}: CategoryProductsSectionProps) => {
  const { t } = useTranslation('home');
  const l0CategoryKey = section?.category?.ancestors?.at(0)?.key;
  const categoryUrl = constructCategoryUrl(l0CategoryKey, section.category.key);

  return (
    <div className={cn('px-4', 'xl:px-0')}>
      <CategoryProductsSectionCarousel
        products={section.products}
        title={section.title}
        categoryUrl={categoryUrl}
        hasMore={section.hasMore}
        seeAllTitle={t('See all')}
      />
    </div>
  );
};
